import React, {useEffect, useState} from 'react';
import MainNavbar from "../../components/main-navbar";
import OracleApi from "../../service/oracle.api";
import {
  Alert,
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Paper,
  Snackbar,
  styled,
  TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

function App() {
  const [orderId, setOrderId] = useState<number>(0);
  const [order, setOrder] = useState<Order>({
    orderId: '',
    customerId: '',
    customerName: '',
    customerEmail: '',
    visitDate: '',
    totalAmount: '',
    status: '',
    isSend: false,
    urlBarcode: [],
    barcode: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openPwi, setOpenPwi] = useState<boolean>(false);
  const [sendPwiData, setSendPwiData] = useState(false);
  const [sendMailData, setSendMailData] = useState({
    status: 500,
    message: '',
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    size: 'small',
    elevation: 50,
  }));

  async function searchOrder(){
    try {
      const responsePwi = await OracleApi.getOrderPWI(orderId.toString()).catch(() => {
        return null;
      });

      const responseEmail = await OracleApi.getOrderEmail(orderId.toString()).catch(() => {
        return null;
      });

      const data: Order = {
        orderId: responsePwi?.orderId ?? responseEmail?.orderId ?? orderId.toString(),
        customerId: responsePwi?.customerId ?? 'Sem dados',
        customerName: responseEmail?.customerName ?? 'Sem dados',
        customerEmail: responseEmail?.customerEmail ?? 'Sem dados',
        visitDate: responseEmail?.visitDate ?? 'Sem dados',
        totalAmount: responseEmail?.totalAmount ?? 'Sem dados',
        status: responsePwi?.status ?? 'PENDENTE',
        isSend: responseEmail?.isSend ?? false,
        urlBarcode: responsePwi?.urlBarcode ?? [],
        barcode: responsePwi?.barcode ?? [],
      };

      setOrder(data);
    } catch (e) {
      console.log(e);
    }
  }

  async function sendEmail(){
    setLoading(true);

    await OracleApi.resendOrderEmail(orderId.toString()).then((result) => {
      setSendMailData({
        status: result.status,
        message: result.message,
      });
      setOpen(true);
      setLoading(false);
    }).catch((error) => {
      setSendMailData({
        status: error?.status,
        message: 'Erro ao enviar e-mail',
      });
      setOpen(true);
      setLoading(false);
    });
  }

  async function sendPwi(){
    setLoading(true);

    await OracleApi.resendOrderPwi(orderId.toString()).then((result) => {
      setSendPwiData(true);
      setOpenPwi(true);
      setLoading(false);
    }).catch((error) => {
      setSendPwiData(false);
      setOpenPwi(true);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (orderId !== 0) {
      searchOrder().then();
    }
  }, [loading]);

  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <header>
        <MainNavbar />
      </header>
      <Box sx={{ maxWidth: 1080, margin: 'auto', padding: 2 }}>
        <Card variant="outlined" title="Consulta de Pedidos" sx={{ mb: 2 }}>
          <CardHeader title="Consultar Pedido" sx={{ textAlign: 'center' }} />
          <CardContent>
            <Box sx={{ alignItems: 'center', justifyContent: 'flex', justifyItems: 'start', display: 'flex' }}>
              <TextField id="order-input" label="Pedido" variant="outlined" size='medium' fullWidth={true} value={orderId === 0 ? '' : orderId} onChange={(event: any) => {
                setOrderId(event.target.value);
              }} />
              <Button type="button" variant="contained" size='small' sx={{ py: 2 }} onClick={() => searchOrder()}>
                <SearchIcon/>
              </Button>
            </Box>
          </CardContent>
        </Card>
        {
          order.orderId &&
            <Card variant="outlined" title="Dados do Pedido" sx={{ my: 2 }}>
                <CardHeader title="Dados do Pedido" sx={{ textAlign: 'center' }} />
                <CardContent>
                    <Grid2 container rowSpacing={1} columnSpacing={{ lg: 4, md: 3, sm: 2, xs: 1 }} columns={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                        <Grid2 lg={3} md={4} sm={6} xs={12}>
                            <Item elevation={4}>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Cliente ID
                                </Typography>
                                <Typography variant="h5" gutterBottom component="div">
                                  { order.customerId }
                                </Typography>
                            </Item>
                        </Grid2>
                        <Grid2 lg={3} md={4} sm={6} xs={12}>
                            <Item elevation={4}>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Pedido ID
                                </Typography>
                                <Typography variant="h5" gutterBottom component="div">
                                  { order.orderId }
                                </Typography>
                            </Item>
                        </Grid2>
                        <Grid2 lg={3} md={4} sm={6} xs={12}>
                            <Item elevation={4}>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Nome do Cliente
                                </Typography>
                                <Typography variant="h5" gutterBottom component="div">
                                  { order.customerName }
                                </Typography>
                            </Item>
                        </Grid2>
                        <Grid2 lg={3} md={4} sm={6} xs={12}>
                            <Item elevation={4}>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Email do Cliente
                                </Typography>
                                <Typography variant="h5" gutterBottom component="div">
                                  { order.customerEmail }
                                </Typography>
                            </Item>
                        </Grid2>
                        <Grid2 lg={3} md={4} sm={6} xs={12}>
                            <Item elevation={4}>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Status PWI
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    <Chip label={order.status}
                                          icon={order.status === 'PROCESSADO' ? <DoneIcon /> : <CancelIcon />}
                                          color={order.status === 'PROCESSADO' ? 'success' : 'error'}
                                    />
                                </Typography>
                            </Item>
                        </Grid2>
                        <Grid2 lg={3} md={4} sm={6} xs={12}>
                            <Item elevation={4}>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Status Email
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    <Chip label={order.isSend ? 'ENVIADO' : 'PENDENTE'}
                                          icon={order.isSend ? <DoneIcon /> : <CancelIcon />}
                                          color={order.isSend ? 'success' : 'error'}
                                    />
                                </Typography>
                            </Item>
                        </Grid2>
                        <Grid2 lg={12} md={12} sm={12} xs={12}>
                            <Item elevation={4}>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Barcodes
                                </Typography>
                                <div>
                                  { order.urlBarcode.map((barcode, index) => {
                                    return <img src={`https://hh-vouchers.s3.sa-east-1.amazonaws.com/${barcode}`}  alt='qrcode' width={200}/>
                                  }) }
                                </div>
                            </Item>
                        </Grid2>
                        <Grid2 lg={12} md={12} sm={12} xs={12}>
                            <Item elevation={1} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button type="button" variant="contained" size='small' sx={{ py: 2, mr: 2 }} onClick={() => sendPwi()}>
                                    Reprocessar PWI
                                </Button>
                                <Button type="button" variant="contained" size='small' sx={{ py: 2, mr: 2 }} onClick={() => sendEmail() }>
                                    Reprocessar Email
                                </Button>
                                <Button type="button" variant="contained" size='small' sx={{ py: 2 }} onClick={() => searchOrder() }>
                                    Atualizar Status
                                </Button>
                            </Item>
                        </Grid2>
                        <Grid2 lg={12} md={12} sm={12} xs={12}>
                            <Alert severity="warning">
                                Atenção: se o STATUS PWI estiver como processado, será necessario a exclusão do sistema da PWI antes de ser reprocessado.
                            </Alert>
                        </Grid2>
                        <Grid2 lg={12} md={12} sm={12} xs={12}>
                            <Alert severity="warning">
                                Atenção: se o STATUS EMAIL estiver como PENDENTE e o EMAIL DO CLIENTE estiver SEM DADOS é possivel que o pedido não esteja aprovado no ORACLE.
                            </Alert>
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Card>
        }
        <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={6000}>
          <Alert severity={sendMailData.status <= 200 ? 'success' : 'error'} sx={{ width: '100%' }}>
            {sendMailData.message}
          </Alert>
        </Snackbar>
        <Snackbar open={openPwi} onClose={() => setOpenPwi(false)} autoHideDuration={6000}>
          <Alert severity={sendPwiData ? 'success' : 'error'} sx={{ width: '100%' }}>
            {sendPwiData ? 'O pedido será reprocessado em breve!' : 'Erro ao reprocessar pedido!'}
          </Alert>
        </Snackbar>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  );
}

export default App;

export type Order = {
  orderId: string,
  customerId: string,
  customerName: string,
  customerEmail: string,
  status: string,
  urlBarcode: string[],
  barcode: string[],
  visitDate: string,
  totalAmount: string,
  isSend: boolean,
}

// https://hh-vouchers.s3.sa-east-1.amazonaws.com/1688400122594.png