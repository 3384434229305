import axios from "axios";

class OracleApi {
  private static readonly API_ORACLE_URL = 'https://services.hopihari.ig.com.br/hh-queue';
  private static readonly API_EMAIL_URL = 'https://services.hopihari.ig.com.br/hh-mail';

  public static async getOrderPWI(orderId: string): Promise<OrderPwi> {
    const url = `${this.API_ORACLE_URL}/integrate/pwi/${orderId}`;
    const { data } = await axios.get<OrderItemPwi[]>(url);

    return {
      orderId: data[0].orderId,
      customerId: data[0].customerId,
      status: data[0].status,
      urlBarcode: data.map((item) => item.urlBarcode),
      barcode: data.map((item) => item.barcode),
    };
  }

  public static async getOrderEmail(orderId: string): Promise<OrderEmail> {
    const url = `${this.API_EMAIL_URL}/email/order/${orderId}`;
    const { data } = await axios.get<OrderItemEmail>(url);

    return {
      orderId: data.order_id,
      customerName: data.customer_name,
      customerEmail: data.customer_email,
      visitDate: data.visit_date,
      totalAmount: data.total_amount,
      isSend: data.is_send,
    }
  }

  public static async resendOrderEmail(orderId: string): Promise<any> {
    const url = `${this.API_EMAIL_URL}/email/resend/${orderId}`;
    const { data } = await axios.get<any>(url);

    return data;
  }

  public static async resendOrderPwi(orderId: string): Promise<boolean> {
    const url = `${this.API_ORACLE_URL}/integrate/pwi/${orderId}/reprocess`;
    const response = await axios.put<any>(url).catch((error) => {
      console.log(error);
      return error.response;
    });

    return response.status === 204;
  }
}

export type OrderItemPwi = {
  orderId: string,
  customerId: string,
  status: string,
  urlBarcode: string,
  barcode: string,
}

export type OrderPwi = {
  orderId: string,
  customerId: string,
  status: string,
  urlBarcode: string[],
  barcode: string[],
}

export type OrderItemEmail = {
  order_id: string,
  customer_name: string,
  customer_email: string,
  visit_date: string,
  total_amount: string,
  is_send: boolean,
}

export type OrderEmail = {
  orderId: string,
  customerName: string,
  customerEmail: string,
  visitDate: string,
  totalAmount: string,
  isSend: boolean,
}

export default OracleApi;